export function footerFunc() {
  console.log('フッター関連JS');
}

//sp版グローバルメニュー全体余白調整
let getSpGlobalMenueF,resizecheckF;
export function footerWhitespaceAdjustment() {
  console.log('ddddd');
  resizecheckF = $('#responsibleCheck').width();
  getSpGlobalMenueF = $('#spGlobalMenue').height();
  if(resizecheckF == 2 ){
    $('small').css('padding-bottom',getSpGlobalMenueF);
  }else{
    $('small').removeAttr('style');
  }
}