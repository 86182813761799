import anime from 'animejs/lib/anime.es.js';

//グローバルメニュー開閉関数
export function subNavOpenCloseFunc() {
  let dtOac , resizecheckDt;
  dtOac = $('.oAc');
  dtOac.on('click', function () {
    resizecheckDt = $('#responsibleCheck').width();
    if (resizecheckDt == 2) {
      $(this).next('dd').slideToggle();
      $(this).find('a').toggleClass('open');
      return false;
    }
  });
}


export function buttonActionFunc() {
  let shareBtnSp, shareLayout, globalMenue;
  globalMenue = $('#globalMenue');
  shareBtnSp = $('#shareBtnSp');
  shareLayout = $('.shareLayout');

  //開ける
  function shareNavOpen() {
    anime({
      targets: '.shareBox',
      translateY: {
        value: ['100vh', '0'],
        duration: 1000,
        easing: 'easeOutQuart',
      },
      duration: 800
    });
  }
  //閉じる
  function shareNavClose() {
    anime({
      targets: '.shareBox',
      translateY: {
        value: ['0', '-100vh'],
        duration: 500,
        easing: 'easeInQuint',
      },
      direction: 'normal',
    });
  }

  //シェアボタン押した時
  shareBtnSp.on('click', function () {
    if ($(shareLayout).hasClass('addOn')) {
      shareNavClose();
      anime({
        targets: '#shareMusk',
        opacity: {
          value: [1, 0],
          duration: 800,
          easing: 'easeOutQuart',
        },
        duration: 800,
        complete: function () {
          shareLayout.removeClass('addOn');
        }
      });
    } else {
      shareLayout.addClass('addOn');
      anime({
        targets: '#shareMusk',
        opacity: {
          value: [0, 1],
          duration: 800,
          easing: 'easeOutQuart',
        },
        duration: 800
      });
      shareNavOpen();
    }
  });
}