import anime from 'animejs/lib/anime.es.js';
let globalMenue, resizecheckH, menueBtnSpH,Scrollpos;
globalMenue = $('#globalMenue');
menueBtnSpH = $('#menueBtnSp');
resizecheckH = $('#responsibleCheck').width();
export function headerFunc() {
  function resetCssFunc() {
    $('#header').removeAttr('style');
  }

  //メニュ開ける
  function openNavi() {
    anime({
      targets: '#header',
      translateX: {
        value: ['-100%', '0'],
        duration: 1000,
        easing: 'easeInOutExpo',
      },
      complete: function () {}
    });
  }

  //メニューを閉じる
  function closeNavi() {
    anime({
      targets: '#header',
      translateX: {
        value: ['0', '-100%'],
        duration: 1000,
        easing: 'easeInOutExpo',
      },
      complete: function () {
        resetCssFunc();
      }
    });
  }

  //sp版グローバルメニュー
  menueBtnSpH.on('click', function () {
    $('#header').toggleClass('open');
    if ($('#header').hasClass('open')) {
      openNavi();
      Scrollpos = $(window).scrollTop();
      $('body').addClass('fixedBody').css({
        'top': -Scrollpos
      });
    } else {
      closeNavi();
      $('body').removeClass('fixedBody').css({
        'top': 0
      });
      window.scrollTo(0, Scrollpos);
    }
  });
}


export function headerResizeFunk() {
  let getSpGlobalMenue;
  resizecheckH = $('#responsibleCheck').width();
  getSpGlobalMenue = $('#spGlobalMenue').height();
  if(resizecheckH == 2 ){
    $('#header .inner').css('padding-bottom',getSpGlobalMenue);
  }else{
    $('#header .inner').removeAttr('style');
    $('#header').removeAttr('style');
  }
}