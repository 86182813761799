import objectFitImages from 'object-fit-images';
import {
  styleResetFunc,
} from "./common/_common";
import {
  hoverFunc
} from "./common/_hoverFunc";
import {
  pageLinkAnimationFunc
} from "./common/_pageLinkAnimation";
import {
  headerFunc,
  headerResizeFunk
} from "./common/_header";
import {
  footerFunc,
  footerWhitespaceAdjustment
} from "./common/_footer";
import {
  buttonActionFunc,
  subNavOpenCloseFunc
} from "./common/_buttonAction";
import {
  idxFunc
} from "./pages/_index";
//* -------------------------------------------------------------------
//通常実行
//------------------------------------------------------------------- */
let indexTopLen, setTimer, setResponsibleCheck;
//* ----------------------------------------------------------------------------------
//window実行
//---------------------------------------------------------------------------------- */
////// ロード時
let indexTop = $('#indexTop').length;
console.log(indexTop);
$(window).on('load', function () {
  styleResetFunc();
  headerFunc();
  idxFunc();

  //共通系
  pageLinkAnimationFunc();
  buttonActionFunc();
  headerResizeFunk();
  footerWhitespaceAdjustment();
  subNavOpenCloseFunc();
});

// リサイズ時
$(window).on('resize', function () {
  headerResizeFunk();
  footerWhitespaceAdjustment();
});

// スクロール時
$(window).on('scroll', function () {

});
///* ----------------------------------------------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//---------------------------------------------------------------------------------- */
var uaFlag = 0;
if ((
    navigator.userAgent.indexOf('iPhone') > 0) ||
  (navigator.userAgent.indexOf('iPod') > 0) ||
  (navigator.userAgent.indexOf('iPad') > 0) ||
  (navigator.userAgent.indexOf('Android') > 0) ||
  (navigator.userAgent.indexOf('BlackBerry') > 0) ||
  (navigator.userAgent.indexOf('PlayBook') > 0) ||
  (navigator.userAgent.indexOf('Kindle') > 0) ||
  (navigator.userAgent.indexOf('Silk') > 0) ||
  (navigator.userAgent.indexOf('Windows Phone') > 0) ||
  (navigator.userAgent.indexOf('Nokia') > 0)
) {
  // スマホ・タブレット時の処理
  uaFlag = 1;
} else {
  // PC時の処理
  uaFlag = 3;
  hoverFunc();
}
/* ----------------------------------------------------------------------------------
IEだけの時
---------------------------------------------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}